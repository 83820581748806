import React, {useEffect} from 'react';
import './audio-header.sass'

const HistoryHeader = () => {


    return(

        <header>
            <div className="header-content">ИСТОРИЯ</div>
        </header>
    )
}
export default HistoryHeader;
