import React from 'react';
import './audio-header.sass'

const EventsHeader = () => {


    return(

        <header>
            <div className="header-content">СОБЫТИЯ</div>
        </header>
    )
}
export default EventsHeader;
