import React from 'react';
import './audio-header.sass'

const FunClubHeader = () => {


    return(

        <header>
            <div className="header-content"><nobr>ФАН-КЛУБ</nobr></div>
        </header>
    )
}
export default FunClubHeader;
