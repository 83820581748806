const stateCodes = () => {
    return {
        "01": "The Republic of Adygea",
        "02": "The Republic of Bashkortostan",
        "03": "The Republic of Buryatia ",
        "04": "The Altai Republic",
        "05": "The Republic of Dagestan",
        "06": "The Republic of Ingushetia",
        "07": "The Kabardino-Balkar Republic",
        "08": "The Republic of Kalmykia",
        "09": "The Karachay-Cherkess Republic",
        "10": "The Republic of Karelia",
        "11": "The Komi Republic",
        "12": "The Mari El Republic",
        "13": "The Republic of Mordovia",
        "14": "The Republic of Sakha (Yakutia)",
        "15": "The Republic of North Ossetia – Alania",
        "16": "The Republic of Tatarstan",
        "17": "The Tyva Republic",
        "18": "The Udmurt Republic",
        "19": "The Republic of Khakassia",
        "20": "The Chechen Republic",
        "21": "The Chuvash Republic - Chuvashia",
        "22": "Altai Krai",
        "23": "Krasnodar Krai",
        "24": "Krasnoyarsk Krai",
        "25": "Primorsky Krai",
        "26": "Stavropol Krai",
        "27": "Khabarovsk Krai",
        "28": "Amur Oblast (region)",
        "29": "Arkhangelsk Region",
        "30": "Astrakhan Region",
        "31": "Belgorod Region",
        "32": "Bryansk Region",
        "33": "Vladimir Region",
        "34": "Volgograd Region",
        "35": "Vologda Region",
        "36": "Voronezh Region",
        "37": "Ivanovo Region",
        "38": "Irkutsk Region",
        "39": "Kaliningrad Region",
        "40": "Kaluga Region",
        "41": "Kamchatka Krai",
        "42": "Kemerovo Region",
        "43": "Kirov Region",
        "44": "Kostroma Region",
        "45": "KurganRegion",
        "46": "Kursk Region",
        "47": "Leningrad Region",
        "48": "Lipetsk Region",
        "49": "Magadan Region",
        "50": "Moscow Region",
        "51": "Murmansk Region",
        "52": "Nizhny Novgorod Region",
        "53": "Novgorod Region",
        "54": "Novosibirsk Region",
        "55": "Omsk Region",
        "56": "Orenburg Region",
        "57": "Orel Region",
        "58": "Penza Region",
        "59": "Perm Krai",
        "60": "Pskov Region",
        "61": "Rostov Region",
        "62": "Ryazan Region",
        "63": "Samara Region",
        "64": "Saratov Region",
        "65": "Sakhalin Region",
        "66": "Sverdlovsk Region",
        "67": "Smolensk Region",
        "68": "Tambov Region",
        "69": "Tver Region",
        "70": "Tomsk Region",
        "71": "Tula Region",
        "72": "Tyumen Region",
        "73": "Ulyanovsk Region",
        "74": "Chelyabinsk Region",
        "75": "Zabaykalsky Krai",
        "76": "Yaroslavl Region",
        "77": "Moscow",
        "78": "Saint-Petersburg",
        "79": "Jewish Autonomous Oblast",
        "83": "Nenets Autonomous Okrug",
        "86": "Khanty-Mansi Autonomous Okrug",
        "87": "Chukotka Autonomous Okrug",
        "89": "Yamalo-Nenets Autonomous Okrug",
        "91": "Crimea Republic",
        "92": "Sevastopol"
    }
}
export {stateCodes};
