import React, {useEffect} from 'react';
import './audio-header.sass'

const VideoHeader = () => {


    return(

        <header>
            <div className="header-content">ВИДЕО</div>
        </header>
    )
}
export default VideoHeader;
