import React  from 'react';
import './audio-header.sass'

const AudioHeader = () => {


    return(

        <header>
            <div className="header-content">АУДИО</div>
        </header>
    )
}
export default AudioHeader;
