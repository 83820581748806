import React from 'react';
import './audio-header.sass'

const ConcertsHeader = () => {


    return(

        <header>
            <div className="header-content">КОНЦЕРТЫ</div>
        </header>
    )
}
export default ConcertsHeader;
