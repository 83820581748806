import React from 'react';
import './history.sass'
import './full-history.sass'
import photo1 from './Концерт в 10шк. 2002г..jpg'
import photo2 from './Scan10045.jpg'
import photo3 from './Вадим Поляков 2005г..png'
import photo4 from  './Репетиция на даче.png'
import photo5 from  './DSCF3510.JPG'

const FullHistory = () => {

    return(

        <div className="history-content">
            <div className="text-content">
                <h1>2002-2003: НАЧАЛО</h1>
                <p>
                    «Ну что, Сэм, будешь у нас басистом!» - хлопнув по плечу, одним осенним утром 2002-го года сообщил
                    Иван Дерябин Самсону Емельянову перед началом уроков<br/>
                    в школе, которой ребята вместе учились. Таким образом продолжилась начавшаяся несколько загодя
                    история коллектива ВИА шк. №10 г. Тольятти и начался музыкальный
                    экспририенс для Самсона, который в последствии трансформировался<br/>
                    в проект «Ракурс цели».<br/><br/>

                    Иван (вокал/соло-гитара) уже играл в составе ВИА, тогда в его состав входили некоторые учащиеся
                    старших классов, а так же бывшие участники группы «Немезис»,
                    гитаристом в которой был Виктор Золотов, произведший несгладимое впечатление<br/>
                    на Самсона своим умением играть и сочинять песни. На тот момент времени это был уже опытный гитарист
                    с богатым бэкграундом.<br/><br/>

                    Композиция «Крыша» (на данный момент не исполняется) стала наверное самым главным хитом школы,
                    авторство на которую принадлежит именно ему.
                    Виктор несколько раз посещал репетиции группы и иногда даже принимал в них непосредственное участие
                    на инструменте. На ритм-гитаре уже играл Роман Поляков,
                    за ударные сел Евгений Тушканов. Как и Самсон, последний не имел никакого опыта игры, но подал
                    надежды на кастинге, который устроил тогдашний руководитель ВИА (о нем – ниже).<br/><br/>

                    Вообще идея играть что-то свое была изначально интегрирована в процесс жизни ВИА, одновременно с
                    этим никто не отменял, будем так говорить, «штатную» деятельность ансамбля,
                    которой предусматривались выступления на разного рода утренниках и прочих красных днях календаря.
                    Примерно в такой дихотомии и будет проходить последующая жизнь коллектива.<br/><br/>

                    Руководителем ВИА стал Александр Михайлович Фомченко, в прошлом тренер по борьбе и по
                    совместительству гитарист в стиле блюз.
                    Это был состоявшийся мужчина средних лет, уже известный по г. Тольятти своей работой в различных
                    учебных заведениях и опытом организации подобных ВИА.
                    Именно ему принадлежала вся аппаратура, на которой репетировал школьный ансамбль.
                    Прошлый же состав ВИА самораспустился, с одной стороны, из-за выпуска старшеклассников из школы, а с
                    другой, - из-за смены руководителя ВИА. До осени 2002 года им заведовал другой
                    не менее опытный музыкант (бас-гитарист) и в прошлом коллега Александра Михайловича по цеху.<br/><br/>

                    Еще до прихода Самсона в коллектив Иван и Роман уже вели творческую деятельность, сочиняли песни.
                    На тексты Федотовой Миланы, с которой Иван тогда поддерживал романтические взаимоотношения, были
                    написаны несколько песен в стиле поп-рок.
                    Одна из них («Я не верю слезам…») впоследствии исполняется «Ракурсом» как «Живи, пока живой».
                    Самсону эта композиция особенно понравилась, т.к. на ней звучит его первое басовое соло, написать
                    которое тогда ребята озадачили начинающего басиста.
                    Ему пришлось над этим покрапеть. В новейшей версии был переписан текст (он был во всем хорош, но
                    очень сильно веял юношеским романтическим максимализмом и резко выбивался из будущей стилистики
                    «Ракурса»), изменена аранжировка, написано основное гитарное соло.<br/><br/>

                    В целом, этот период характеризуется глобальным становлением музыкальных интересов Самсона.
                    Здесь были и внеплановые репетиции во время уроков, вечерние сборы «на поиграть» после уроков.
                    Коллектив выступил на джем-сейшене в Тольяттинской Филармонии, на который А.М. собрал заметное
                    количество тольяттинских рок-групп.
                    Так же довелось отыграть на «Радуге Надежд», встретить живым звуком бывших выпускников на вечер
                    встречи, поздравить рабочий коллектив и учащихся школы с Днем Победы и исполнить одноименную
                    композицию с Александром Михайловичем на гитаре и вокале. А так же завершить обучение в школе и
                    отыграть финальный выпускной.<br/><br/>

                    На этом, история этого состава ВИА заканчивается. Иван поехал покорять Москву
                    и поступил в ВТУ им. Щепкина. Самсон и Евгений поступили в Тольяттинский государственный
                    университет, Роман же уже был студентом. Далее начались университетские годы…<br/><br/><br/>
                </p>
                <h1>2003-2008: УНИВЕРСИТЕТ</h1>
                <p>Говоря об этом периоде, стоит несколько слов сказать о периоде предшествующем всем описанным выше
                    событиям.
                    Начало своей музыкальной деятельности Самсон начал не в качестве бас-гитариста ВИА шк. №10, а
                    несколько загодя,
                    летом 2002-го, купив себе акустическую гитару и неспешно разучивая по песеннику популярные на тот
                    момент песни известных рок-коллективов России и зарубежья (Metallica, Guano Apes, Nirvana, Ария,
                    Мастер, Черный Обелиск, Би-2, Сплин и т.д.).<br/><br/>

                    Впоследствии, именно владение обоими инструментами (гитарой и бас-гитарой)
                    на уровне достаточном для сочинения, записи и сведения материала стало проводником к
                    сочинительству практически всего репертуара «Ракурса». Участвуя
                    в ВИА шк. №10 вклад Самсона в творчество группы ограничивался упомянутым выше бас-соло на
                    композиции «Я не верю слезам…», а так же басовым слэповым риффом
                    в песне «Путник» (на данный момент не исполняется). О собственных композициях
                    с нуля речи не шло. <br/>
                    Вернувшись с летних каникул, оставшиеся трое уже бывших участника школьного ансамбля решили не
                    бросать музыку и предприняли попытку продолжить начатое.
                    Роман стал основным вокалистом группы, продолжив аккомпанировать себе на ритме. В качестве
                    репетиционной базы первое время ребята продолжили играть
                    у Александра Михайловича в школе, правда, уже на платной основе. Место соло-гитариста осталось
                    незанятым. Здесь же встал вопрос о стилистике и названии коллектива.
                    Называться было решено «Пункт назначения», со стилистикой оказалось несколько сложнее. <br/><br/>

                    Здесь хочется особенно подчеркнуть тот момент, что исполняемое ранее творчество целиком и
                    полностью было связано с деятельностью Дерябина Ивана (музыка)
                    и Федотовой Миланы (тексты). Роман не имел своих творческих амбиций, хотя
                    и всегда склонялся несколько более в сторону исполняемого ранее поп-рока. Евгений также не писал
                    свой материал.
                    Самсон же всегда тяготел к несколько более фундаментальному тяжелому року и к этому времени уже
                    начал предпринимать попытки писать свои композиции.
                    <br/><br/>

                    Проиграв несколько репетиций в школе, музыканты находят в Центральном районе города
                    уникальную репетиционную базу. Оборудована она была в подвале одного
                    из домов, на первом этаже которого располагался компьютерный клуб «Интеграл». Заведовал этой
                    базой Валентин Ланской (группа «Автостоп»).
                    Было решено перебраться туда. Как минимум, предлагаемый владельцами аппарат был несколько
                    более профессиональным за ту почасовую ставку, которую теперь приходилось платить (Евгению
                    больше не приходилось играть на барабанах ножками от стула после поломки палочек…).
                    <br/><br/>

                    Помимо тех вещей, которые ребята играли ранее и о которых речь шла выше, одной из новых
                    композиций в репертуаре стала песня, которая впоследствии исполняется «Ракурсом» как
                    «Судьба».
                    Тогда на эту песню так и не удалось сформировать внятный текст (хотя Романом такие
                    попытки предпринимались), зато был создан задел в виде вступления, аккордов на куплет и
                    басовый слэповый рифф на припев.
                    Это была первая композиция под авторством Самсона. Еще одна композиция была написана в
                    тот же период уже с текстом - «Грызут друг другу кости» (на данный момент не
                    исполняется).
                    Одновременно с этим на горизонте группы появился Вадим Поляков. Это был хороший знакомый
                    Романа по ТГУ. Он тогда первоначально занял вакантное место соло-гитариста.
                    Впоследствии, на несколько ближайших лет это был ближайший соратник Самсона по
                    творчеству и соавтор практически всех вещей, которые тогда играл коллектив.
                    Сама же группа перебирается репетировать в университетскую гримерку. <br/><br/>
                    «Пункт назначения» отыграл пару концертов в бывшей школе и университете, пережил
                    достаточно большой период поиска оптимального баланса интересов участников и как
                    следствие перетасовок в составе музыкантов.
                    В коллектив приходили играть Илья Свешников (соло-гитара) и Максим Фролов (барабаны),
                    впоследствии сформировавшие коллектив «Герцеговина Флор», а так же еще некоторые
                    музыканты. Итогом же всего это стали ликвидация «Пункта назначения»
                    и формирование коллектива «Ветер Никайны». <br/><br/>

                    В составе: Сергей Якименко – вокал/гитара, Вадим Поляков – гитара, Самсон Емельянов –
                    бас-гитара, Семен Корсаков – барабаны.
                    Творческая инициатива в данном коллективе принадлежала Сергею. Песни он писал
                    в стиле, конечно, не лав-рок ВИА шк. №10, но очень похожий философский поп-рок
                    с размышлениями о себе и тому подобном. Самсон и Вадим же толкали коллектив
                    в сторону чего-то более взрослого. Успешно закончив репетировать композицию Сергея
                    «Состояние», группа начинает писать новую песню. У Сергея на эту песню была зарисовка
                    риффа на вступление и рифф на припев.
                    Рифф на куплеты, оставшуюся аранжировку, а так же соло партии к песне пишет Самсон. Так
                    был написан «Ветер перемен» и эта композиция в последствии исполняется «Ракурсом» как
                    «Вперед!» (текст был переписан по причинам схожим с «Я не верю слезам…»). Еще одну,
                    ставшую впоследствии кавером на старославянские мотивы, вещь принес Вадим. Называлась
                    она « Intro» и изначально планировалась к применению по названию.
                    Однако, случайная репетиция с девушками из руководства ТГУ «по культуре» открыла эту
                    композицию в совершенно новом свете. С таким материалом коллектив отыграл концерт на
                    фестивале ТГУ «На семи ветрах».
                    <br/><br/>

                    Дальнейшая деятельность коллектива, к сожалению, оказалась затруднительна. С одной
                    стороны, из-за разницы творческих интересов вокалиста и остальных участников, с
                    другой – Вадим, так же как и Сергей, имел амбиции фронтмена группы.
                    На одной из репетиций было принято решение разойтись. Самсон и Вадим стали играть
                    отдельно, Сергей отдельно. Семен же влился в альтернативный рок-бэнд «6 Chamber».
                    <br/><br/>
                    Еще во время игры с «Ветер Никайны» Самсон летом 2004-го сочиняет на акустике
                    перебор, впоследствии ставший отправной точкой к композиции «Забвение». Эта
                    песня станет одной из главных вещей группы и исполняется «Ракурсом» по сей день
                    в неизмененном виде. После расставания с Сергеем Якименко Вадим пишет на
                    композицию текст, прикручивается аранжировка, пишутся басовые и соло-партии.
                    Неожиданно вспоминается композиция «Путник», исполнявшаяся несколько лет тому
                    назад в ВИА, так же переаранжируется, дописываются новые бас и соло партии.
                    Вадим со своей стороны пишет новые песни: «Сделаны в СССР» (на данный момент
                    не исполняется), а так же «8 грамм» (так же не исполняется). На обе песни
                    пишется аранжировка, Самсон пишет басовые и соло партии. Параллельно с
                    сочинительством, коллектив продолжает поиски музыкантов в свой состав, которые,
                    к сожалению,
                    ни к чему не приводят. Приходящие на прослушивания люди либо не дотягивают
                    технически, либо имеют иные музыкальные интересы, или же какие-то другие
                    признаки девиантного поведения.
                    <br/><br/>

                    С таким бэкграундом ребята идут записывать минус-треки к Юрию Андрюшину
                    (группа «5 элемент») в Тольяттинскую Филармонию. На песни «Забвение»,
                    «Сделаны
                    в СССР» и «Путник» Юрий с коллегами по студии звукозаписи делают парням
                    минусовки, пишутся барабанные партии, записываются недостающие для игры «под
                    минус» гитары. Какое-то время ребята под них репетируют.
                    <br/><br/>

                    Параллельно музыканты сами пытаются освоить звукозапись. У Вадима дома
                    организовывается импровизированная студия и пишется новый материал.
                    Самсон наигрывает несколько отрывков (вступление, куплет, припев, бридж
                    и соло), из которых в будущем появится исполняемая «Ракурсом» композиция
                    под названием «ГЕТТО». Но тогда Вадим из этих кусков сводит композицию
                    «Американское шоу»
                    (на данный момент не исполняется), текст на которую представляет
                    политический пропагандистский монолог от имени тогдашнего американского
                    президента
                    о господстве Соединенных Штатов в мире, взятый из видео в сети «Сказка
                    про демократию/Откровения Джорджа Буша».
                    Помимо прочего, делается любительский клип на песню «Забвение», видеоряд
                    для которой взят из параллельной для Вадима творческой деятельности.
                    Так же, Самсон написал свое чисто басовое соло, которое Вадим успешно
                    дополнил гитарными партиями и даже написал текст. Эта песня стала
                    называться «Джонатан» (на данный момент не исполняется).
                    Основная тема из этого бас-соло впоследствии звучит на композиции
                    «Шторм». <br/><br/>

                    История умалчивает, как свела Вадима с двумя парнями, которые играли на
                    экзотических для тяжелого рока инструментах, но в дальнейшем им довелось
                    отыграть вместе один концерт.
                    Первый играл на там-тамах, другой играл на духовом инструменте типа
                    флейта. На долгосрочное сотрудничество с ними никто больших надежд не
                    возлагал, но сыграть на «Грин-шоу» концерт, пусть и даже в совсем
                    не свойственном для себя амплуа, оказались не прочь. Из исполняемого
                    ранее репертуара были взяты отдельные куски гитарных и басовых партий,
                    добавлены партии упомянутых выше инструментов и съаранжированы в
                    «шаманский релакс мьюзик».
                    Это были три вещи: две из них - инструментальные композиции, и одна -
                    песня, смысл которой по сей день остается загадкой.
                    Вадим исполнял импровизированный на английском текст(«…your soul, your
                    body!»), но главным словом в припеве и как следствие словом, по которому
                    эту композицию запомнили, было – «Рапочекупика!».
                    <br/><br/>

                    Что это такое? - тогда Вадим оставил в секрете. Дальше выяснять это
                    не пришлось…
                    Тут хочется отметить, что в целом к концу 2000-х в России, да и по
                    всему миру
                    в целом популярность рок-музыки стала резко сокращаться (позднее
                    даже появится мем - «Верни мне мой 2007-й»). Музыканты это в целом
                    уловили.
                    Помимо прочего, близился выпускной из ВУЗа и начало трудовых будней.
                    Самсон пошел трудиться в НТЦ ВАЗа, а так же занялся наукой,
                    продолжив обучение в аспирантуре. Вадим же уехал
                    в Санкт – Петербург поступать учиться на артиста в СПБГУКИ. На этом
                    университетские годы подходили к концу.<br/><br/><br/>
                </p>
                <h1>2008-2014: «РОК ДЛЯ СВОИХ»</h1>
                <p>
                    Первые несколько лет из этого периода Самсон не вел никакой творческой деятельности. Ему тогда было
                    не до музыки, ибо прибавить к работе на режимном предприятии и научной работе в университете
                    творчество не получалось совсем.
                    Все ограничивалось перебором струн на акустике и исполнении любимых каверов иногда на выходных в
                    баре/пабе/ресторане (либо же на природе). Все изменилось в 2011 году.
                    Неожиданный интерес к проекту и готовность попробовать себя в качестве гитариста и поэта изъявил
                    Тестов Дмитрий, бывший одногруппник и хороший товарищ Самсона. У последнего также высвободилось
                    время для творчества после окончания научной деятельности.
                    <br/><br/>

                    Все началось с покупки инструмента и усиления к нему Дмитрием. Самсон же просто достал из шкафа
                    запыленный бас и также приобрел к нему басовый комбоусилитель. Репетировать стали у Дмитрия на
                    даче, посезонно. Первое время речь и не шла
                    о перезапуске проекта, просто играли для себя любимые кавера «Черного Обелиска», «Арии»,
                    «Мастера», «Металлики» и т.п. Также к проекту присоединился еще один бывший
                    одноклассник/одногруппник и друг Самсона - Мысин Алексей.
                    Последний, кстати, уже имел ранее отношение к ВИА шк. №10, правда к параллельной, так называемой
                    неосновной группе. Надо сказать, все будущие участники «дачного ВИА» ранее так или иначе были
                    одноклассниками/одногруппниками/друзьями. В их число вошел и Евгений Тушканов, на долгое время
                    пропавший с рок-н-рольных радаров
                    со времен игры в школе. Правда, поначалу его участие ограничивалось исключительно заслушиванием
                    того, что делает новоиспеченное дружеское трио, барабанной установки на тот момент у коллектива
                    не было.
                    <br/><br/>

                    Отыграв один сезон старые добрые хиты любимых групп, музыканты решают воскресить в том или
                    ином виде проект и пробуют писать недостающие партии к уже имеющимся композициям, с целью
                    переаранжировать их так, что бы сформировался полноценный альбом, выдержанный в более или
                    менее однообразном стиле.
                    Первым делом пишутся барабаны в Guitar Pro. Далее Дмитрий приступает к написанию текстов.
                    Самсон переписывает аранжировки. Параллельно с этой работой коллектив решает обзавестись
                    дополнительным оборудованием для звукозаписи.
                    <br/><br/>

                    Приобретаются новые более профессиональные гитары, а также дополнительное
                    к уже имеющемуся, усиление к ним. Первым делом пишутся композиции «ГЕТТО»(бывш.
                    «Американское шоу»), «Живи, пока живой…»(бывш. «Я не верю слезам…»), «Судьба» (ранее не
                    исполнялась, зарисовки Самсон писал еще в 2003г.), «Загнанный в плен»(бывш. «Сделаны в
                    СССР» - на данный момент не исполняется), «Ведьма-ночь»(бывш. «Путник» - на данный
                    момент не исполняется). Это были вещи, которые раньше существовали в том или ином виде в
                    прошлом. Помимо прочего,
                    у Самсона появляются и новые идеи. Первой новой вещью становится «Игра». Дмитрий также
                    пишет на эту композицию текст. Помимо всего, именно в этот период времени ребята
                    задумываются о названии группы. Самсон предлагает несколько вариантов, которые он
                    предварительно «гуглит» на околовоенную «боевую» тематику. После некоторых обсуждений и
                    согласований выбор падает на «Ракурс цели».
                    <br/><br/>

                    Репетируя на даче посезонно(примерно 3-4 месяца в году), музыканты чувствовали свою
                    ограниченность.
                    С одной стороны, по релевантному для репетиций времени в году. А с другой, после
                    приобретения нового оборудования, в том числе и барабанной установки, встает вопрос
                    о невозможности продолжать репетиции/студийную работу в дачном кооперативе, т.к.
                    уровень звука начинает беспокоить соседей даже в дневное время.
                    Таким образом, встает вопрос о смене репетиционной базы. Здесь появляется идея
                    проследовать старым добрым американским традициям и переехать сначала
                    в арендованный, а потом и вовсе купить гараж. <br/><br/>

                    Музыканты покупают гараж в ГСК-33 в Комсомольском районе города, отыгрывают
                    на свадьбе у своего хорошего товарища концерт и благополучно переезжают туда.
                    Последующий период будет логическим продолжением «рока для своих», только
                    в новых и очень интересных обстоятельствах. <br/><br/><br/>
                </p>
                <h1>2014-2019: ГАРАЖ</h1>
                <p>
                    Переехав в гараж, музыканты продолжают работу над материалом, осваивают звукозапись. Параллельно
                    ведется работа над новым материалом в планируемый альбом.
                    Следующей композицией, которую пишет Самсон, становится «Шторм» - переделанное в полноценную
                    инструментальную композицию бывшее бас-соло «Джонатан».
                    Так же встает вопрос о написании флагманской композиции, одноименной с названием коллектива. На
                    рубеже 2014-2015 годов коллектив пишет таковую…(«Ракурс цели»).
                    Здесь же у коллектива появляются первые зарисовки демо записей. <br/><br/>

                    Примерно тогда же, осенью 2014 года на горизонте
                    группы появляется Константин Ладейщиков. Остальные участники коллектива познакомились с ним на
                    неформальном акустическом концерте возле «ДК Тольятти». Константин по иронии судьбы находился рядом
                    и не мог не обратить внимание
                    на звук гитары – в прошлом, он уже был вокалистом в группе «Переход». Так же
                    по иронии судьбы был знаком и сотрудничал с Валентином Ланским. В общем, было
                    о чем поговорить и вспомнить былые времена… Константин примыкает к группе
                    в качестве второго вокалиста и переодически замещает Алексея, когда тот уезжает
                    в плановые среднесрочные командировки по основной работе в Германию. <br/><br/>

                    С того времени гараж становится несколько большим, чем просто репетиционной базой. Здесь нужно
                    сказать, что практически все выходные и не только музыканты
                    и их друзья/знакомые стали проводить именно там. Фактически гараж стал пабом/баром с живым звуком и
                    регулярными концертами.
                    <br/><br/>

                    Некоторые изменения произошли с группой в 2015-м. Тогда по личным причинам ее покинул Тестов
                    Дмитрий. По словам последнего, ему «стало в тягость» дальнейшее занятие музыкой.
                    Всвязи с этим на гитаре с тех пор стал играть Самсон, продолжая сочинять и записывать бас
                    партии. Так же он продолжил кропотливую работу по записи демо (иногда в гараже даже приходилось
                    оставаться ночевать…).
                    Еще одной новинкой того периода стала инструментальная композиция «Метрополитен»(название на эту
                    песню рабочее).
                    <br/><br/>

                    Именно в этот момент Самсон доводит до некоторого завешенного консистентного состояния
                    демо-альбом, исполнив все партии на записи.
                    Небольшой промежуток времени группа пыталась играть «под минус». Параллельно с этим пытаясь
                    найти новый состав в свои ряды.
                    Одним из новых знакомств становится знакомство с Федором Мамыкиным(учредитель и директор
                    Hard Rock Pub).
                    <br/><br/>

                    Федор, помимо бизнеса, проявлял интерес к искусству и писал свои песни. А также
                    переодически собирал у себя в пабе разных музыкантов на Jam Sassion. Тогда же Самсон
                    встречает уже знакомого по подобным «джемам» Дмитрия Шейкина. В прошлом
                    им довелось какое количество раз сыграть в баре «Друзья» в 2012-м. Последний также
                    становится завсегдатаем в гараже и чуть позже садится за ударные, сменив
                    на этом «посту» Евгения Тушканова(последнему долгие тренировки до необходимого до
                    «боевого солдата» уровня оказались тяжелы).
                    А с Федором Самсону довелось какое время посотрудничать и даже выпустить совместную
                    композицию «Пятница, вечер!». Эта песня стала открывать практически все концерты.
                    <br/><br/>

                    Оставался нерешенным вопрос с вакантным местом бас-гитариста. Один из летних
                    гаражных концертов летом 2016-го года посетил брат Константина – Дмитрий Ладейщиков.
                    В прошлом он также играл с братом в вышеупомянутой группе. С тех пор он занял это
                    вакантное место. <br/><br/>

                    Начался период жизни группы, в принципе, впервые, так или иначе,
                    в укомплектованном виде, с имеющимся на борту материалом для «промоушена»
                    и репетиционной базой/студией звукозаписи. За это время было много концертов
                    и репетиций. Несколько раз в гараже даже довелось отметить Новый Год! Коллектив
                    делает кавер на песню «Дождь»(экс «Переход»), так же Самсон пишет аранжировки
                    на некоторые вещи Дмитрия Шейкина(Дмитрий – мультинструменталист, и, кстати, он
                    более известен в узких кругах как вокалист, а не барабанщик), у которого есть свой
                    авторский материал(«Всегда тебя я буду помнить…», «Россия!»).
                    <br/><br/>

                    Несмотря на вышеперечисленное, основной проблемой коллектива остается его
                    «местячковость». Это был не проект, в котором все участники ставят цель стать
                    частью российского/мирового рок-н-рольного сообщества.
                    Он оставался проектом «для своих», основной целью большинства участников
                    которого, как показала практика, было приятное времяпрепровождение после
                    трудовых будней, нежели работа над проектом, его «промоушен» и творчество. Само
                    собой, по личным причинам у каждого члена группы, постепенно коллектив стал
                    распадаться. Последним концертом стало выступление группы уже дуэтом Самсона и
                    Константина
                    в баре «Синяя дыня». <br/><br/><br/>
                </p>
                <h1>2020-???: ОДИНОЧЕСТВО</h1>
                <p>
                    Проект жив… Сэм продолжает писать материал, который потенциально может стать основой для уже нового
                    альбома.
                    Пандемия и события 2020 года, помимо прочего, заставляют последнего осваивать программирование и
                    начать разработку веб-сайта группы. Если вы сейчас читаете это – значит ему это удалось... Проект
                    нуждается
                    в кадрах, которые решают все, как завещал один уже покойный деятель…
                </p>
            </div>
            <div className="media-content">
                <img className="media-content-photo media-content-photo__1" src={photo1} alt="photo"/>
                    <div className="media-content-photo-description">Самсон Емельянов – слева, Иван Дерябин – по центру,<br/> Евгений
                        Тушканов – сзади, Роман Поляков – справа.</div>
                    <img className="media-content-photo media-content-photo__2" src={photo2} alt="photo"/>
                        <div className="media-content-photo-description">Самсон Емельянов – справа, Евгений Тушканов –
                            по центру с друзьями.
                            <br/> Уличные акустические концерты «для своих» имели особенную популярность в те времена.
                        </div>
                        <img className="media-content-photo media-content-photo__3" src={photo3} alt="photo"/>
                        <div className="media-content-photo-description">Вадим Поляков. Фото с концерта «На семи
                            вертах». ТГУ, 2005 г.
                        </div>
                        <iframe className="wind-of-change" width="508" height="380" src="https://www.youtube.com/embed/9ZG6nrCq1G0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <iframe className="oblivion" width="508" height="380" src="https://www.youtube.com/embed/lujCwsGQGag" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <iframe className="rapo" width="508" height="380" src="https://www.youtube.com/embed/MHcQQqg9bVA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <img className="media-content-photo media-content-photo__4" src={photo4} alt="photo"/>
                        <div className="media-content-photo-description">Дмитрий Тестов – слева, Алексей Мысин –
                            по центру, Самсон Емельянов – справа.
                            <br/> На репетиции. 2013г.</div>
                        <iframe className="oblivion-bar" width="508" height="380" src="https://www.youtube.com/embed/qjLp3dDvkfs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <img className="media-content-photo media-content-photo__5" src={photo5} alt="photo"/>
                        <div className="media-content-photo-description"> Г. Тольятти. ГСК-33. 2015г. Самсон
                            и Евгений – в нижнем ряду в гараже,
                            <br/>Алексей и Константин – в верхнем ряду по центру и справа соответственно.
                        </div>
                        <iframe className="getto" width="508" height="380" src="https://www.youtube.com/embed/inyvaVsqCNQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <iframe className="friday" width="508" height="380" src="https://www.youtube.com/embed/0ul1xxMT_F4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        </div>
    )

}

export default FullHistory;
