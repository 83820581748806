import React from 'react';
import MenuList from '../menu-list';

const ShopPage = () => {
    return (
        <MenuList/>
    )
}

export default ShopPage;
